.slider-simple-wrapper {
  position: relative;
  margin-bottom: @paragraph-margin-bottom * 2;
}
.slider-simple {
  width: 900px;
  height: 566px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
.slider-simple-item {
  & img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}
.slider-simple__button-prev,
.slider-simple__button-next {
  position: absolute;
  z-index: 5;
  top: 50%;
  border: 0;
  background-color: transparent;
  padding: 0;
  transform: translateY(-50%);

  &:focus { outline: 0; }

  &:hover {
    & .icon {
      fill: @primary-color;
    }
  }

  & .icon {
    fill: #0c4733;
    transition: 0.2s fill ease;
  }
}
.slider-simple__button-prev {
  left: 0;
  & .icon {
    transform: rotate(90deg);
  }
}
.slider-simple__button-next {
  right: 0;
  & .icon {
    transform: rotate(-90deg);
  }
}

// слайдеры

.slider {
  &__side {
    position: relative;
    width: 100%;
    background-color: #fff;
    padding-top: 23px;
    padding-bottom: 14px;
    padding-left: 16px;
    padding-right: 16px;
    border: 1px solid #e1e1e1;
    margin-bottom: 20px;
    & h1, & .h1 {
      font-family: 'robotoslab';
      font-weight: 700;
      border-bottom: 1px solid #e1e1e1;
      padding-bottom: 7px;
    }
    &_controls {
      position: absolute;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center;
      top: 9px;
      right: 16px;
      width: 66px;
      height: 34px;
      & a {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        width: 33px;
        height: 34px;
        border: 1px solid #e1e1e1;
        &[aria-disabled="true"] svg {
          fill: #e1e1e1;
        }
        & svg {
          height: 15px;
        }
        &.slider__side_arrow-prev {
          border-right: none;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          & svg {
            transform: rotate(90deg);
          }
        }
        &.slider__side_arrow-next {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          & svg {
            transform: rotate(-90deg);
          }
        }
        
      }
    }
    &_wrapper {
      width: 100%;
    }
    &_item {
      position: relative;
      transition: opacity ease-in-out 0.8s;
      & .img {
        height: 321px;
        max-width: 244px;
        background-repeat: no-repeat;
        background-size: contain;
        margin-left: auto;
        margin-right: auto;
      }
      & .caption {
        font-size: 13px;
        text-align: center;
        padding-top: 2px;
        padding-bottom: 3px;
      }
      & .btn {
        width: 100%;
        text-align: center;
        &.read {
          margin-bottom: 10px;
        }
      }
      & .slider__tooltip {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%);
        width: 200px;
        height: auto;
        text-align: center;
        color: #fff;
        background-color: rgba(0,0,0,0.7);
        border-radius: 10px;
        padding: 10px;
        opacity: 0;
        transition: opacity ease-in-out 0.8s;
      }
      &:hover .slider__tooltip {
        opacity: 1;
      }
    }
  }
}

@media (max-width: @screen-md) {
  .slider-simple {
    width: 710px;
    height: 447px;
  }
}
@media (max-width: @screen-sm) {
  .slider-simple {
    width: auto;
    height: auto;
    max-height: 458px;
  }
}
