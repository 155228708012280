/**
* form style
*/
.site-form {
  // label
  @form-label-text-color:         #565a5f;
  @form-label-font-size:          14px;
  @form-label-font-weight:        400;
  // elements (input, textarea)
  @form-input-height:             40px;
  @form-elements-border-radius:   0;
  @form-textarea-min-height:      110px;
  @form-textarea-resize:          vertical; //  none | both | horizontal | vertical | inherit

  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;

  &-wrapper {
    border: 2px solid @form-focus-control-color;
    margin-bottom: 40px;
  }

  &-element-wrapper {
    position: relative;
    margin-bottom: 20px;
  }

  &__label {
    display: block;
    width: 100%;
    font-size: @form-label-font-size;
    font-weight: @form-label-font-weight;
    color: @form-label-text-color;
  }

  &__input {
    display: block;
    width: 100%;
    font-family: 'proxima_nova';
    height: 50px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    background-color: #fafafa;
    padding-left: 15px;
    padding-right: 15px;

    transition-duration: 0.15s;
    transition-property: background-color;
    transition-timing-function: ease-in;

    &:focus {
      outline: 0;
      border-color: #e6e6e6;
      background-color: #fafafa;
    }
    &:hover {
      background-color: #fafafa;
    }
  }

  &__textarea {
    display: block;
    width: 100%;
    font-family: 'proxima_nova';
    min-height: @form-textarea-min-height;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    background-color: #fafafa;
    padding: 15px;
    resize: none;

    transition-duration: 0.15s;
    transition-property: background-color;
    transition-timing-function: ease-in;

    &:focus {
      border-color: #e6e6e6;
      outline: 0;
      background-color: #fafafa;
    }

    &:hover {
      background-color: #fafafa;
    }
  }

  &__input.error {
    border-color: @form-error-border-color;
    background-color: @form-error-background-color;
    &:focus {
      outline: 0;
    }
  }

  & label.error {
    left: @grid-gutter-width / 2;
    font-size: 0.85rem;
    line-height: 1.2;
    color: @form-error-border-color;
  }
  & .attach {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    & .caption {
      width: 100%;
      margin-bottom: 10px;
    }
    & input {
      position: absolute;
      top: 34px;
      left: 0;
      opacity: 0;
      width: 150px;
      padding: 0;
      cursor: pointer;
    }
    & label {
      position: relative;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      align-items: center;
      color: #9a1a06;
      text-decoration: underline;
      cursor: pointer;
      z-index: 1;
    }
    & svg {
      width: 14px;
      height: 13px;
      margin-left: 7px;
      cursor: pointer;
    }
    & .attach_monitor {
      margin-left: 15px;
    }
  }

  & .radio {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    & .caption {
      width: 100%;
      margin-bottom: 15px;
    }
    &__wrapper {
      position: relative;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 26px;
      &:last-child {
        margin-right: 0;
      }
      & .text {
        margin-left: 12px;
      }
      & input {
        width: 20px;
        height: 20px;
        cursor: pointer;
        opacity: 0;
        & + label:before, + label:after {
          position: absolute;
          top: 0;
          left: 0;
          width: 22px;
          height: 22px;
          cursor: pointer;
        }
        &:not(checked) + label:before {
          content: url('../img/forms/radio.png');
        }
        &:not(checked) + label:after {
          content: url('../img/forms/radio.png');
        }
        &:checked + label:before {
          content: url('../img/forms/radio-active.png');
        }
        &:checked + label:after {
          content: url('../img/forms/radio-active.png');
        }
      }
    }
  }

  & .radio-new {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    & .caption {
      width: 100%;
      margin-bottom: 15px;
    }
    & .radio__wrapper {
      position: relative;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 26px;
      & input {
        width: 20px;
        height: 20px;
        cursor: pointer;
        opacity: 0;
        & + label:before, + label:after {
          position: absolute;
          top: 0;
          left: 0;
          width: 22px;
          height: 22px;
          cursor: pointer;
        }
        &:not(checked) + label:before {
          content: url('../img/forms/radio.png');
        }
        &:not(checked) + label:after {
          content: url('../img/forms/radio.png');
        }
        &:checked + label:before {
          content: url('../img/forms/radio-active.png');
        }
        &:checked + label:after {
          content: url('../img/forms/radio-active.png');
        }
      }
      
      &:last-child {
        margin-right: 0;
      }
      & .text {
        margin-left: 12px;
      }
      & input {
        width: 20px;
        height: 20px;
        cursor: pointer;
        opacity: 1;
        margin-right: 5px;
      }
      & label {
        
      }
    }
  }

  & .checkbox {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &__wrapper {
      position: relative;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 26px;
      &:last-child {
        margin-right: 0;
      }
      & .text {
        margin-left: 12px;
      }
      & input {
        width: 20px;
        height: 20px;
        cursor: pointer;
        opacity: 0;
        & + label:before, + label:after {
          position: absolute;
          top: 0;
          left: 0;
          width: 22px;
          height: 22px;
          cursor: pointer;
        }
        &:not(checked) + label:before {
          content: url('../img/forms/checkbox.png');
        }
        &:not(checked) + label:after {
          content: url('../img/forms/checkbox.png');
        }
        &:checked + label:before {
          content: url('../img/forms/checkbox-active.png');
        }
        &:checked + label:after {
          content: url('../img/forms/checkbox-active.png');
        }
      }
    }
  }

  & .newpass.hidden {
    display: none;
  }

  & a {
    color: #9a1a06;
  }

}


/**
* checkbox
*/
.site-form {

  &-row--checkbox {
    position: relative; /* for label with error */
    display: flex;
    margin-bottom: 50px;

    & label.error {
      position: absolute;
      left: 0;
      top: 100%;
    }
  }

  &__label-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 21px;
    height: 21px;
    min-width: 21px;
    border-radius: 3px;
    border: 1px solid @gray--darken;
    background-color: @gray;
    padding: 3px;
    cursor: pointer;
    margin-right: 10px;
    transition: 0.15s border-color ease-in;
  }

  &__icon-checked {
    width: 20px;
    height: 20px;
    fill: @primary-color;
    transition: 0.15s opacity ease-in;
    opacity: 0;
  }

  &__input-checkbox {
    &:checked {
      & ~ .site-form__label-checkbox .site-form__icon-checked {
        opacity: 1;
      }
    }
    &:focus {
      & ~ .site-form__label-checkbox {
        border-color: @form-focus-control-color;
      }
    }
    &.error ~ .site-form__label-checkbox{
      border-color: @form-error-border-color;
      background-color: @form-error-background-color;
    }
  }

  &-checkbox-text {
    font-size: 12px;
    color: #818080;
    font-weight: 300;

    & a {
      font-size: 12px;
    }
  }

  &-checkbox-text__link {
    color: @link-color;
    text-decoration: underline;
    &:hover {
      color: @link-hover;
    }
  }
}

// хедер поиск
.nav__main_search {
  .site-form-wrapper {
    // width: 100%;
    width: 81.7%;
    height: 100%;
    margin-bottom: 0;
    border: none;
    // border-left: 1px solid #e1e1e1;
    @media (min-width: 1024px) and (max-width: 1199px) {
      width: 93.8%;
    }
    @media (min-width: 769px) and (max-width: 1023px) {
      width: 92.4%;
    }
  }
  &.expanded {
    @media (max-width: 1199px) {
      width: 100% !important;
      max-width: 100% !important;
      flex-basis: 100% !important;
    }
    .site-form-wrapper {
      width: 95%;
      @media (min-width: 769px) and (max-width: 1023px) {
        width: 93.7%;
      }
    }
  }
  .site-form {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  .site-form-element-wrapper {
    height: 100%;
    margin: 0;
  }
  .site-form__input {
    position: absolute;
    left: -312%;
    width: 412%;
    font-family: 'proxima_nova';
    font-style: italic;
    height: 100%;
    border: none;
    color: #000;
    background-color: #fafafa;
    @media (min-width: 769px) and (max-width: 1199px) {
      left: 0;
      width: 100%;
    }
    &::placeholder {
      color: #9f9f9f;
    }
    &::-webkit-input-placeholder {
      color: #9f9f9f;
    }
    &::-moz-placeholder {
      color: #9f9f9f;
    }
    &:-moz-placeholder {
      color: #9f9f9f;
    }
    &:-ms-input-placeholder {
      color: #9f9f9f;
    }
  }
}

// мобильный поиск
.header__mob-search-wrapper {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  @media (max-width: 579px) {
    max-height: 50px;
  }
  &.site-form-wrapper {
    width: 100%;
    margin-bottom: 0;
    border: none;
    @media (max-width: 579px) {
      // border-bottom: 1px solid #e1e1e1;
    }
  }
  & .site-form {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  & .site-form-element-wrapper {
    height: 100%;
    margin: 0;
  }
  & .site-form__input {
    font-family: 'proxima_nova';
    font-style: italic;
    height: 100%;
    border: none;
    outline: none;
    color: #000;
    background-color: #fafafa;
    padding-left: 70px;
    padding-right: 65px;
    &::placeholder {
      color: #9f9f9f;
    }
    &::-webkit-input-placeholder {
      color: #9f9f9f;
    }
    &::-moz-placeholder {
      color: #9f9f9f;
    }
    &:-moz-placeholder {
      color: #9f9f9f;
    }
    &:-ms-input-placeholder {
      color: #9f9f9f;
    }
  }
}

// карточка товара поиск
.item-list__top_search {
  border: none;
  margin-bottom: 0;
  & .site-form {
    padding-left: 0;
    padding-right: 0;
    max-width: 495px;
    margin-left: 0;
  }
  & .site-form-element-wrapper {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
  }
  & .site-form__input {
    font-family: 'proxima_nova';
    font-style: italic;
    height: 48px;
    background-color: #fff;
    border-color: #e1e1e1;
    border-radius: 0;
  }
  &-button {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52px;
    background-color: #fff;
    border: 1px solid #e1e1e1;
    border-left: none;
    & svg {
      width: 20px;
      height: 20px;
      fill: #555555;
    }
  }
}

.item-list__top .site-form a {
  color: #fff;
}

// оформление заявки

#order-exec {
  & .region {
    margin-bottom: 20px;
    & .region__links {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
      & span {
        color: #9a1a06;
        padding-right: 5px;
        border-right: 1px solid #000;
        margin-right: 5px;
        text-decoration: none;
        order: 1;
        &:last-child {
          padding-right: 0;
          border-right: none;
          margin-right: 0;
        }
        &.blank_region {
          display: none;
        }
      }
    }
  }
  & .site-form__textarea {
    height: 129px;
  }
}

// карточка заявки
#order-full {
  padding-left: 0;
  padding-right: 0;
  & button:focus {
    outline: none;
  }
  & .item-list__main_cell.head  {
    white-space: nowrap;
  }
}

// поиск хедер (новый)



@media (max-width: @screen-sm) { /* 768px по умолчанию */
  .content .site-form__button-submit,
  .site-form__button-submit {
    padding-left: 20px;
    padding-right: 20px;
  }
  .content .site-form,
  .site-form {
    padding-top: 40px;
  }
}
