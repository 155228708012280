@font-face {
  font-family: "roboto";
  src: url("../fonts/roboto/roboto-regular/roboto-regular.woff2?v=1.1.0") format("woff2"),
  url("../fonts/roboto/roboto-regular/roboto-regular.woff?v=1.1.0") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "roboto";
  src: url("../fonts/roboto/roboto-bold/roboto-bold.woff2") format("woff2"),
  url("../fonts/roboto/roboto-bold/roboto-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

// proxima nova

@font-face {
  font-family: "proxima_nova";
  src: url("../fonts/proxima_nova/proxima_nova-regular.woff2") format("woff2"),
  url("../fonts/proxima_nova/proxima_nova-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "proxima_nova";
  src: url("../fonts/proxima_nova/proxima_nova-bold.woff2") format("woff2"),
  url("../fonts/proxima_nova/proxima_nova-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "proxima_nova";
  src: url("../fonts/proxima_nova/proxima_nova-light.woff2") format("woff2"),
  url("../fonts/proxima_nova/proxima_nova-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

// robotoslab

@font-face {
  font-family: "robotoslab";
  src: url("../fonts/robotoslab/robotoslab-regular.woff2") format("woff2"),
  url("../fonts/robotoslab/robotoslab-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "robotoslab";
  src: url("../fonts/robotoslab/robotoslab-bold.woff2") format("woff2"),
  url("../fonts/robotoslab/robotoslab-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "robotoslab";
  src: url("../fonts/robotoslab/robotoslab-light.woff2") format("woff2"),
  url("../fonts/robotoslab/robotoslab-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

/**
* rouble font
*/
// @font-face {
//     font-family: 'rouble';
//     src: url('../fonts/rouble-webfont.woff') format('woff'),
//          url('../fonts/rouble-webfont.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }
