.pagination,
.content .pagination {
  @pagination-main-color:         @link-color;
  @pagination-align:              center; // left, center, right
  @pagination-margin-bottom:      @paragraph-margin-bottom * 2;
  @pagination-font-size:          16px;
  @pagination-font-weight:        500;
  @pagination-text-color:         #000;
  @pagination-control-size:       42px; // размер одного пункта (квадрат)


  margin-bottom: 20px;
  display: inline-flex;
  align-items: center;
  margin-left: 0;
  padding-left: 0;

  &-wrapper {
    text-align: @pagination-align;
  }

  & li {
    width: 41px;
    height: 40px;
    margin-right: 10px;
    margin-bottom: 0;
    padding-left: 0;

    &::before { display: none !important; }

    &:last-child {
      margin-right: 0;
    }
    @media (max-width: 419px) {
      width: 31px;
      height: 30px;
      margin-right: 5px;
    }
  }

  & a,
  & span {
    display: block;
    width: 100%;
    height: 100%;
    font-family: 'robotoslab';
    color: @pagination-text-color;
    font-size: @pagination-font-size;
    font-weight: @pagination-font-weight;
    text-decoration: none;
    text-align: center;
    line-height: 42px;
    border-bottom: 3px solid transparent;
    background-color: #e9e9e9;
    transition: 0.15s all ease-in-out;

    &:visited {
      color: @pagination-text-color;
    }

    &:hover {
      background-color: #d9d9d9;
      border-bottom-color: transparent;
      color: #000;
    }

    &.active {
      background-color: @pagination-main-color;
      color: #fff;
      cursor: default;
    }
    &.disabled {
      color: #777;
      cursor: not-allowed;
      background-color: #fff;
      border-color: transparent;
      & .icon {
        fill: #777;
      }
    }
    @media (max-width: 419px) {
      font-size: 14px;
      line-height: 32px;
    }
  }


  & .pagination__prev,
  & .pagination__next {
    border-bottom: 0;
    .icon {
      width: 16px;
      height: 16px;
      fill: @pagination-main-color;
      transition: 0.15s fill ease-in;
      @media (max-width: 419px) {
        width: 12px;
        height: 12px;
      }
    }

    &:hover {
      // background-color: transparent;
      color: inherit;
    }
  }


}
.pagination__next {

  & .icon {
    transform: rotate(-90deg);
  }
}

.pagination__prev {

  & .icon {
    transform: rotate(90deg);
  }
}

.board-list {
  & .pagination-wrapper {
    text-align: right;
  }
}

.news-list-full--news {
  & .pagination-wrapper {
    text-align: center;
    width: 100%;
  }
}

@media (max-width:@screen-md) {
  .content .pagination,
  .pagination {
    flex-wrap: wrap;
    margin-bottom: 20px;

    & li {
      margin-bottom: 20px;
    }
  }
}
