.content .tab,
.tab {
  @tab-text-color:                #656565;
  @tab-text-background-color:     #eff0f1;
  @tab-text-transform:            uppercase;
  @tab-control-max-width:         350px;

  padding-left: 0;
  margin-left: 0;
  display: flex;
  align-items: center;
  width: 100%;

  &-content {
    margin-bottom: 40px;
  }

  & li {
    flex-grow: 1;
    padding-left: 0;
    margin-right: 10px;
    max-width: @tab-control-max-width;

    &:last-child {
      margin-right: 0;
    }

    &::before {
      content: none;
    }

    &.active {
      & a {
        cursor: default;
        background-color: @primary-color;
        color: #fff;
      }
    }
  }

  & a {
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: @tab-text-background-color;
    text-transform: @tab-text-transform;
    font-size: 16px;
    color: @tab-text-color;
    padding-top: 9px;
    padding-bottom: 7px;
    text-decoration: none;
    border-bottom: 0;
    transition: 0.2s background-color ease-in;

    &:hover {
      color: @primary-color;
    }
  }
}


.content .tab.tab--catalog,
.tab.tab--catalog {
  width: ~'calc(100% - 2px)';
  margin-bottom: 0;

  & li {
    border: 2px solid gray;
    border-bottom: 0;
    border-left: 0;
    margin-right: 0;
    border-radius: 2px;
    position: relative;
    left: 2px;
    margin-left: -2px;
    border-left: 2px solid gray;

    &:first-child {
      border-left: 2px solid gray;
    }

    &.active {
      border: 2px solid @primary-color;
      border-bottom-color: #fff;
      z-index: 10;
      top: 2px;
      margin-top: -2px;
      overflow: hidden;

      & a {
        position: relative;
        left: -2px;
        background-color: #fff;
        color: @primary-color;
        border-left: 2px solid @primary-color;
      }
    }
  }

  a {
    padding-left: 15px;
    padding-right: 15px;
    border-left: 2px solid transparent;
    transition: none;
  }

  & + .tab-content {
    min-height: 200px;
    border: 2px solid @primary-color;
    padding: 22px 15px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  }
}

.tab-content>.tab-pane {
  display: none;
}
.tab-content>.active {
  display: block;
}
.tab-pane.fade {
  opacity: 0;
  transition: opacity .15s linear;
}
.tab-pane.fade.in {
  opacity: 1;
}


@media (max-width: @screen-sm) { /* 768px по умолчанию */
  .content .tab,
  .tab {
    flex-wrap: wrap;

    & li {
      max-width: 100%;
      flex-basis: 100%;
      width: 100%;
      margin-right: 0;
      border: 1px solid @gray--darken;
      border-bottom: 0;

      &:last-child {
        border-bottom: 1px solid @gray--darken;
      }
    }
  }
  .content .tab.tab--catalog,
  .tab.tab--catalog {
    & li {
      border: 1px solid @gray--darken;
      border-bottom: 0;
      border-radius: 0;

      &:first-child {
        border-left: 1px solid @gray--darken;
      }

      &:last-child {
        border-bottom: 1px solid @gray--darken;
      }
      &.active {
        border: 1px solid @gray--darken;
        border-bottom: 0;

        &:last-child {
          border-bottom: 1px solid @gray--darken;
        }
        & a {
          position: static;
          background-color: @primary-color;
          color: #fff;
          border: 0;
        }
      }
    }
    & a {
      position: static;
    }
  }
  .content .tab.tab--catalog+.tab-content,
  .tab.tab--catalog+.tab-content {
    box-shadow: none;
    border: 0;
    min-height: 100%;
    padding: 20px 0 40px;
  }
}
