.select2 {
  //# сам блок
  @select2-block-height:                      32px;
  @select2-block-background-color:            #ececec;
  @select2-block-border-color:                1px solid #aaa;
  @select2-block-border-radius:               1px;
  @select2-block-text-color:                  #646464;

  //# список
  @select2-optins-item-padding:               6px; // влияет на размер одного пункта option
  @select2-options-background-color:          #f0f0f0; // при наведении на пункт
  @select2-options-text-color:                #000;

  @select2-options-selected-background-color: #ddd; // выбранный пункт
  @select2-options-selected-text-color:       #646464;


  display: block;
  width: 100%;
  height: @select2-block-height;
  border-radius: @select2-block-border-radius;
  box-shadow: inset 0 0 24px rgba(0,0,0,0.05);
  &:focus {
    outline: 0;
  }

  &-container .select2-selection--single {
    height: @select2-block-height;

    background-color: @select2-block-background-color;
    box-shadow: inset 0 0 24px rgba(0,0,0,0.05);
    border: @select2-block-border-color;
    border-radius: @select2-block-border-radius;
    &:focus {
      outline: 0;
    }
  }
  &-container--default .select2-selection--single .select2-selection__rendered {
    line-height: @select2-block-height;
    color: @select2-block-text-color;
  }
  &-container--default .select2-selection--single .select2-selection__arrow {
    height: @select2-block-height;
  }
  &-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: @select2-options-background-color;
    color: @select2-options-text-color;
  }
  &-container--default .select2-results__option[aria-selected=true] {
    background-color: @select2-options-selected-background-color;
    color: @select2-options-selected-text-color;
  }
  &-results__option {
    padding: @select2-optins-item-padding;
    font-size: 13px;
    line-height: 1;
  }
}

// карточка товара
.item-list__top {

  & .select2 {
    height: 25px;
  }
  & .select2-container {
    visibility: hidden;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 13px;
    border-radius: 7px;
  }
  & .select2-container .select2-selection--single {
    height: 25px;
    border-radius: 7px;
    background-color: #fafafa;
    border: 1px solid #e1e1e1;
  }
  & .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 25px;
  }
  & .select2-container--default .select2-selection--single .select2-selection__arrow {
    width: 30px;    
    height: 25px;
  }
}

// список товаров
.board-list__main_cell.filter {

  & .select2 {
    height: 25px;
  }
  & .select2-container {
    font-size: 13px;
    border-radius: 7px;
  }
  & .select2-container .select2-selection--single {
    height: 25px;
    border-radius: 7px;
    background-color: #fafafa;
    border: 1px solid #e1e1e1;
  }
  & .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 25px;
  }
  & .select2-container--default .select2-selection--single .select2-selection__arrow {
    width: 20px;    
    height: 25px;
  }
  
  &.type {
    & .select2-container {
      min-width: 60px !important;
      max-width: 60px !important;
      @media (max-width: 767px) {
        min-width: unset !important;
        max-width: unset !important;
      }
    }
  }
  &.standard {
    & .select2-container {
      min-width: 80px !important;
      max-width: 80px !important;
      @media (max-width: 767px) {
        min-width: unset !important;
        max-width: unset !important;
      }
    }
  }
  &.label {
    & .select2-container {
      min-width: 70px !important;
      max-width: 70px !important;
      @media (max-width: 767px) {
        min-width: unset !important;
        max-width: unset !important;
      }
    }
  }

}


// страница новостей
.news-list__switch {

  & .select2 {
    height: 25px;
  }
  & .select2-container {
    font-size: 13px;
    border-radius: 7px;
  }
  & .select2-container .select2-selection--single {
    height: 25px;
    border-radius: 7px;
    background-color: #fafafa;
    border: 1px solid #e1e1e1;
  }
  & .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 25px;
  }
  & .select2-container--default .select2-selection--single .select2-selection__arrow {
    width: 20px;    
    height: 25px;
  }

  & .select2-container {
    min-width: 120px !important;
    max-width: 120px !important;
    @media (max-width: 767px) {
      min-width: unset !important;
      max-width: unset !important;
    }
  }
  

}

// поиск хедер (новый)

#desktop-search-form {
  position: relative;
  // padding-top: 10px;
  // padding-left: 16px;
  // padding-right: 16px;
  height: 100%;
  @media (max-width: 768px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: 579px) {
    height: 50px;
  }
  & .site-form-element-wrapper {
    @media (max-width: 768px) {
      border-left: 1px solid #e1e1e1;
    }
  }
  & .select2.select2-container.select2-container--default {
    height: 100%;
  }
  & .select2-container--default .select2-selection--multiple {
    height: 100%;
    border-radius: 0;
    // border: 1px solid #e1e1e1;
    border: none;
  }
  & .select2-selection__rendered {
    height: 100%;
  }
  & .select2-search__field {
    font-family: proxima_nova,Helvetica,Tahoma,Arial,sans-serif;
    min-width: 120px;
    height: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 0;
  }
  & .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #fafafa;
    border: 1px solid #e1e1e1;
    margin-top: 10px;
  }
  .select2-container--default .select2-selection--multiple .select2-selection__rendered li {
    height: 100%;
    @media (max-width: 768px) {
      &.select2-selection__choice {
        height: auto;
      }
    }
  }
  & button {
    position: absolute;
    top: 0;
    right: 0;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    max-width: 60px;
    height: 100%;
    border: none;
    border-left: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
    background-color: #fff;
    transition: background ease-in-out .2s;
    max-height: 48px;
    &:focus, &:active {
      outline: none;
    }
    @media (max-width: 768px) {
      max-height: 50px;
    }
    // @media (max-width: 1199px) {
    //   border-right: 1px solid #e1e1e1;
    // }
    &:hover {
      background-color: #fafafa;
    }
    & svg {
      fill: #545454;
      width: 20px;
      height: 20px;
    }
  }
}

.select2-dropdown.select2--autocomplete {
  top: 1px;
  border-radius: 0;
  border: 1px solid #e1e1e1;
  & .select2-results__option.select2-results__option--highlighted {
    background-color: #e4f2ec;
    color: #000;
  }
}
