// Collapsible panels (aka, accordion)
//
// Wrap a series of panels in `.panel-group` to turn them into an accordion with
// the help of our collapse JavaScript plugin.

.panel-group {
  margin-bottom: @paragraph-margin-bottom * 2;

  & .panel {
    margin-bottom: 20px;
    border-radius: 0;
  }

  .panel-title {
    margin-bottom: 0;

    & a {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 10px 15px;
      text-decoration: none;
      background-color: @gray;
      transition: 0.15s background-color ease-in;

      & .panel-title-link__text {
        color: @link-color;
      }

      &:hover .panel-title-link__text {
        color: @primary-color;
      }

      &:not(.collapsed) {
        & .panel-title-link__text {
          color: @primary-color;
          font-weight: 500;
          background-color: transparent;
        }

        & .panel-title-link__icon {
          & .icon {
            width: 35px;
            height: 35px;
            fill: @primary-color;
            transform: rotate(0);
          }
        }
      }

    }
  }

  & .panel-heading {
    border-bottom: 0;
  }

  .panel-footer {
    border-top: 0;
    + .panel-collapse .panel-body {
      border-bottom: 1px solid @gray--darken;
    }
  }
}

.panel-body {
  padding: 10px 15px 25px;
}
.panel-default {
  border: 1px solid @gray--darken;

  &.active {
    border-color: @primary-color;
    outline: 1px solid @primary-color;
  }
}
.collapse.in {
  display: block;
}
.collapse {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-timing-function: ease;
  transition-duration: .35s;
  transition-property: height,visibility;
}
.panel-title-link__icon {
  height: 31px;
  & .icon {
    width: 31px;
    height: 31px;
    fill: @link-color;
    transform: rotate(-45deg);
    transition: 0.15s transform ease-in;
  }
}
.panel-title-link__text {
  transition: 0.15s color ease-in;
}

// сайдбар

.sidebar {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  margin-bottom: 20px;
  & h1, & .h1 {
    position: relative;
    font-family: 'robotoslab';
    font-weight: 700;
    padding-top: 17px;
    padding-bottom: 12px;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 0;
    border-bottom: 1px solid #e1e1e1;
    & .sidebar-fire {
      position: absolute;
      top: 50%;
      transform: translateY(-40%);
      right: 16px;
      cursor: pointer;
      display: none;
      & svg {
        width: 30px;
        height: 30px;
        transition: all ease-in-out 0.3s;
        &.active {
          transform: rotate(180deg);
        }
      }
    }
  }
  & .panel-group {
    margin-bottom: 0;
  }
  & .panel-default {
    border: none;
    margin-bottom: 0;
    border-bottom: 1px solid #e1e1e1;
    &:last-child {
      border-bottom: none;
      & .panel-body {
        border-bottom: none;
      }
    }
  }
  & .panel-default.active {
    border: none;
    outline: none;
  }
  & .panel-group .panel-title a {
    background-color: #fff;
    padding: 11px 16px;
    &:hover {
      background-color: #e8d9d5;
    }
  }
  & .panel-group .panel-title a:not(.collapsed) {
    background-color: #e8d9d5;
  }
  & .panel-body {
    position: relative;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #fff;
    border-bottom: 1px solid #e1e1e1;
    padding: 0 0 0;
    padding-top: 24px;
    padding-bottom: 30px;
    & a {
      width: 100%;
      color: #000;
      text-decoration: none;
      line-height: 1.5;
      padding-left: 37px;
      padding-right: 10px;
      transition: background ease-out 0.1s;
      &:before {
        content: '-';
        position: relative;
        left: -5px;
      }
      &:hover {
        background-color: #e4f2ec;
      }
    }
  }
  & .panel-title-link__icon {
    height: 15px;
  }
  & .panel-title-link__icon .icon {
    width: 15px;
    height: 15px;
    transform: rotate(0);
    fill: #000;
  }
  & .panel-group .panel-title a .panel-title-link__text {
    color: #000;
  }
  & .panel-group .panel-title a:not(.collapsed) .panel-title-link__text {
    color: #000;
  }
  & .panel-group .panel-title a:not(.collapsed) .panel-title-link__icon .icon {
    width: 15px;
    height: 15px;
    fill: #000;
    transform: rotate(180deg);
  }
}

// сайдбар расширенный

.sidebar-lg {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  margin-bottom: 20px;
  & h1, & .h1 {
    font-family: 'robotoslab';
    font-weight: 700;
    padding-top: 17px;
    padding-bottom: 12px;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 0;
    border-bottom: 1px solid #e1e1e1;
  }
  & .panel-group {
    margin-bottom: 0;
  }
  & .panel-default {
    border: none;
    margin-bottom: 0;
    border-bottom: 1px solid #e1e1e1;
    &:last-child {
      border-bottom: none;
      & .panel-body {
        border-bottom: none;
      }
    }
  }
  & .panel-default.active {
    border: none;
    outline: none;
  }
  & .panel-group .panel-title a {
    background-color: #fff;
    padding: 11px 16px;
    &:hover {
      background-color: #e8d9d5;
    }
  }
  & .panel-group .panel-title a:not(.collapsed) {
    background-color: #e8d9d5;
  }
  & .panel-body {
    position: relative;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #fff;
    border-bottom: 1px solid #e1e1e1;
    padding: 0 0 0;
    padding-top: 24px;
    padding-bottom: 30px;
    & a {
      width: 100%;
      color: #000;
      text-decoration: none;
      line-height: 1.5;
      padding-left: 37px;
      padding-right: 10px;
      transition: background ease-out 0.1s;
      &:before {
        content: '-';
        position: relative;
        left: -5px;
      }
      &:hover {
        background-color: #e4f2ec;
      }
      &.active {
        background-color: #e4f2ec;
      }
    }
  }
  & .panel-title-link__icon {
    height: 15px;
  }
  & .panel-title-link__icon .icon {
    width: 15px;
    height: 15px;
    transform: rotate(0);
    fill: #000;
  }
  & .panel-group .panel-title a .panel-title-link__text {
    color: #000;
  }
  & .panel-group .panel-title a:not(.collapsed) .panel-title-link__text {
    color: #000;
  }
  & .panel-group .panel-title a:not(.collapsed) .panel-title-link__icon .icon {
    width: 15px;
    height: 15px;
    fill: #000;
    transform: rotate(180deg);
  }
  &__main {
    width: 60%;
  }
  &__side {
    width: 40%;
    border-left: 1px solid #e1e1e1;
    &_heading {
      display: block;
      width: 100%;
    }
    &_list {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
      padding-top: 14px;
      padding-bottom: 14px;
      max-height: 648px;
      overflow-y: auto;
      border-bottom: 1px solid #e1e1e1;

      &-item {
        width: 100%;
        color: #000;
        text-decoration: none;
        padding-left: 10px;
        padding-right: 10px;
        transition: background ease-out 0.1s;
        &:hover {
          color: #000;
          background-color: #e4f2ec;
        }
        &.active {
          color: #000;
          background-color: #e4f2ec;
        }
      }
    }
  }
}

@media (max-width: 579px) {
  .sidebar-fire {
    display: block !important;
  }
}