/**
* Страница поиска
* Содержит в себе следующие компоненты:
* 1. Форма поиска
* 2. Список с результатами
* 3. Дополнительное меню с категориями
* -----------------------------------------------------------------------------
*/
/* variables */
@import "../variables.less";

.search-container {
	padding-left: 16px !important;
	padding-right: 16px !important;
	padding-top: 20px;
	padding-bottom: 30px;
	background-color: #fff;
	border: 1px solid #e1e1e1;
}

.search-form {
	width: 100%;
	margin-bottom: 40px;
}

.search-form-input-wrapper {
	position: relative;
	display: inline;
}

.search-form__label {
	display: block;
	margin-bottom: 15px;
}

.search-form__input {
	display: inline-block;
	width: 100%;
	height: 66px;
	font-size: 16px;
	padding-left: 12px;
	padding-right: 57px;
	border-width: 3px;
	border-color: #e0e0e0;
	border-style: solid;

	&:focus {
		outline: 0;
	}
}

.search-form__button-submit {
	display: inline-block;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 12px;
	width: 40px;
	height: 40px;
	padding: 0;
	border: 0;
	background-color: transparent;

	& .icon {
		width: 40px;
		height: 40px;
		padding: 7px;
		fill: #454545;
	}
}

.search-result-list {
	margin-bottom: 40px;
}

.search-result-item {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	margin-bottom: 30px;

	&:last-child {
		margin-bottom: 0;
	}
}

.search-item-icon-wrapper {
	width: 45px;
	min-width: 45px; // special for ie
	margin-right: 10px;
}

.search-item-icon-inner-wrapper {
	width: 45px;
	height: 45px;
	background-color: #f4f4f4;
	padding: 12px;
}

.search-item-icon-inner-wrapper svg,
.search-item-icon__image {
	display: block;
	max-width: 100%;
	max-height: 100%;
	width: auto;
	height: auto;
}

.search-item-head {
	display: inline-block;
	text-decoration: none;
	margin-bottom: 5px;

	&:hover .search-item-head__text {
		color: @link-hover;
	}
	&:visited .search-item-head__text {
		color: @link-visited;
	}
}

.search-item-head__text {
	color: @link-color;
	text-decoration: underline;
	transition: 0.15s color ease-in;
}

.search-item-head__date {
	color: #666;
	padding-left: 5px;
	text-decoration: none;
}

.search-category-list {
	position: relative;
	// top: -106px;
	margin-bottom: 40px;
}

.search-category-item {
	margin-bottom: 4px;
}

.search-category-item-link {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	align-items: center;
	width: 100%;
	height: 66px;
	background-color: #f4f4f4;
	padding-right: 15px;

	text-decoration: none;
	color: #111;
	font-weight: 600;
	transition: 0.15s background-color ease-in;

	&:hover {
		background-color: #9a1a06;

		& .search-category-item-link__text {
			color: #fff;
		}
		& .search-category-item-link__count {
			color: #e0e0e0;
		}
		& svg {
			fill: #fff;
		}
	}
}

.search-category-item-link--result-all {
	opacity: 0.7;
	background-color: #b73439;
	transition: 0.15s opacity ease-in;

	& .search-category-item-link__text {
		color: #fff;
	}
	& .search-category-item-link__count {
		color: #e0e0e0;
	}
	& svg {
		fill: #fff;
	}
	&:hover {
		opacity: 1;
	}
}

.search-category-item-link-icon-wrapper {
	width: 45px;
	min-width: 45px; // special for ie

	& + .search-category-item-link__text {
		padding-left: 3px;
	}
}

.search-category-item-link-icon-inner-wrapper {
	width: 45px;
	height: 45px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: center;
	align-items: center;

	& svg {
		width: 100%;
		height: auto;
		max-width: 21px;
		transition: 0.15s fill ease-in;
	}
}

.search-category-item-link__text {
	transition: 0.15s color ease-in;
	padding-right: 5px;
	padding-left: 15px;
}

.search-category-item-link__count {
	transition: 0.15s color ease-in;
	color: #666;
}

.paginaion-search-wrapper {
	& .pagination-wrapper {
		text-align: left;
	}
}

.search-item__caption {
    & .highlight {
        background-color: rgba(57, 181, 73, 0.42);
        transition: 0.15s color ease-in;
    }

    & .highlight-separate {
        font-weight: bold;
    }
}

@media (max-width:@screen-md) {
	.search-category-list {
		position: static;
	}
}
