.nested-folder {
  margin-bottom: 40px;
}
.content .folder__head,
.folder__head {
  color: @primary-color;
  margin-bottom: 5px;
}
.folder-list {
  background-color: @gray;
  padding: 15px 30px;
}
.folder-item {
  &:last-child {
    & .folder-item-filename-wrapper {
      margin-bottom: 0;
    }
    & .nested-folder-list {
      padding-bottom: 0;
    }
  }
}
.content .folder-item-filename-wrapper,
.folder-item-filename-wrapper {
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flex;
  display: -o-inline-flex;
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  line-height: 1;
  margin-bottom: 5px;


  &:hover {
    & .icon {
      fill: @link-hover;
    }
    & .folder-item__filename {
      border-bottom-color: @link-hover;
    }
  }
  & .icon {
    position: relative;
    top: 2px;
    display: block;
    width: 18px;
    height: 13px;
    fill: @link-color;
    margin-left: 5px;
    transform: rotate(0);
    transform-origin: center;
    transition: 0.15s transform ease-in;
  }

  &.active {
    color: @link-hover;
    & .icon {
      fill: @link-hover;
      transform: rotate(-180deg);
    }
    & .folder-item__filename {
      border-bottom-color: @link-hover;
    }
  }
}
.folder-item__filename {
  border-bottom: 1px dashed @link-color;
}
.folder-item-icon-wrapper {
  align-self: flex-start;
}

.nested-folder-list {
  padding: 15px 30px;
  background-color: @gray;
}

.nested-folder-item {
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
}

.nested-folder-link {
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flex;
  display: -o-inline-flex;
  display: inline-flex;
  align-items: center;

  &:hover {
    & .icon {
      fill: @link-hover;
    }
  }

  & .icon {
    display: block;
    width: 30px;
    height: 30px;
    fill: @link-color;
    margin-right: 10px;
  }
}
.nested-folder-link-icon-wrapper {
  align-self: flex-start;
}
