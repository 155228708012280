.breadcrumb {
  @breadcrumb-color-normal:       #5a5a5a;
  @breadcrumb-color-hover:        #5a5a5a;
  @breadcrumb-text-transform:     none;
  @breadcrumb-font-weight:        400;
  @breadcrumb-font-size:          14px;
  @breadcrumb-line-height:        18px;
  @breadcrumb-padding-top:        0;
  @breadcrumb-padding-bottom:     14px;
  @breadcrumb-icon-display:       inline-block; // "none" или "inline-block"
  @breadcrumb-separator:          '/';


  list-style-type: none;
  padding-left: 0;
  padding-right: 0;
  padding-left: 3px;
  padding-top: @breadcrumb-padding-top;
  padding-bottom: @breadcrumb-padding-bottom;
  margin: 0;
  font-size: 13px;
  line-height: 13px;
  margin-top: -4px;

  & a {
    font-size: @breadcrumb-font-size;
    line-height: @breadcrumb-line-height;
    font-weight: @breadcrumb-font-weight;
    text-transform: @breadcrumb-text-transform;
    color: @breadcrumb-color-normal;
    text-decoration: none;
    padding-right: 5px;

    &[href] {
      & span {
        text-decoration: underline;
      }
    }

    & span {
      overflow: hidden;
    }

    &[href]:hover {
      color: @breadcrumb-color-hover;
    }
    &::after {
      display: inline-block;
      content: @breadcrumb-separator;
      padding-left: 5px;
      text-decoration: none;
    }
  }
  & li {
    float: left;

    &:last-child a::after {
      display: none;
    }
    &.active a {
      color: @breadcrumb-color-hover;
      & span {
        color: #a73439;
        text-decoration: none;
      }
    }
  }
}
