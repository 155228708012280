/**
* wm buttons
*/
// класс для сброса стилей браузера и создания общих стилей для кнопок
.btn,
.content .btn, {
  @button-padding-top:                14px;
  @button-padding-bottom:             14px;
  @button-padding-left:               30px;
  @button-padding-right:              30px;

  @button-border-width:               1px;
  @button-border-style:               solid;
  @button-border-radius:              0;

  @button-font-size:                  16px;
  @button-font-weight:                400;

  @button-transition-duration:        0.3s;


  //## small button
  @button-sm-padding:                 4px 7px;
  @button-sm-border-radius:           3px;
  @button-sm-font-size:               13px;


  //## button primary
  @button-primary-color:              #fff;
  @button-primary-bg:                 #a73439;
  @button-primary-border:             #a73439;

  @button-primary-color-hover:        #fff;
  @button-primary-bg-hover:           #9a1a06;
  @button-primary-border-hover:       #9a1a06;


  //## button secondary
  @button-secondary-color:              #fff;
  @button-secondary-bg:                 #313131;
  @button-secondary-border:             #313131;

  @button-secondary-color-hover:        #fff;
  @button-secondary-bg-hover:           #000;
  @button-secondary-border-hover:       #000;


  //## button transparent
  @button-transparent-color:          #fff;
  @button-transparent-border:         #fff;

  @button-transparent-color-hover:    @primary-color;
  @button-transparent-bg-hover:       #fff;
  @button-transparent-border-hover:   #fff;


  //## button disabled
  @button-disabled-bg-color:          #bcbcbc;
  @button-disabled-border-color:      #bcbcbc;
  @button-disabled-color:             #7f7f7f;


  //## button link
  @button-link-text-decoration:    none;

  @button-link-color:              @link-color;
  @button-link-bg:                 transparent;
  @button-link-border:             transparent;

  @button-link-color-hover:        @link-hover;
  @button-link-bg-hover:           transparent;
  @button-link-border-hover:       transparent;



  display: inline-block;

  padding-top: @button-padding-top;
  padding-bottom: @button-padding-bottom;
  padding-left: @button-padding-left;
  padding-right: @button-padding-right;

  border-width: @button-border-width;
  border-style: @button-border-style;
  border-radius: @button-border-radius;
  
  font-family: 'robotoslab';
  font-size: @button-font-size;
  font-weight: @button-font-weight;
  line-height: 1.1;
  text-decoration: none;

  cursor: pointer;

  transition-duration: @button-transition-duration;
  transition-timing-function: ease-in;
  transition-property: border-color, background-color;

  &--sm {
    font-family: @main-font;
    padding: @button-sm-padding;
    border: none;
    border-radius: @button-sm-border-radius;
    font-size: @button-sm-font-size;
    color: #5a5a5a;
    background-color: #f0f0f0;
    &:hover {
      background-color: #e4f2ec;
    }
    &:active {
      background-color: #e4f2ec;
    }
  }

  &--primary {
    border-color: @button-primary-border;
    background-color: @button-primary-bg;
    color: @button-primary-color;

    &:hover {
      border-color: @button-primary-border-hover;
      background-color: @button-primary-bg-hover;
      color: @button-primary-color-hover;
    }
  }


  &--secondary {
    border-color: @button-secondary-border;
    background-color: @button-secondary-bg;
    color: @button-secondary-color;

    &:hover {
      border-color: @button-secondary-border-hover;
      background-color: @button-secondary-bg-hover;
      color: @button-secondary-color-hover;
    }
    &:visited {
      color: @button-secondary-color;
    }
  }


  &--link {
    font-family: @main-font;
    border-color: @button-link-border;
    background-color: @button-link-bg;
    color: #000;
    text-decoration: underline;
    transition: color ease-in-out 0.2s;

    &:hover {
      border-color: @button-link-border-hover;
      background-color: @button-link-bg-hover;
      color: @button-link-color-hover;
    }
    &:focus {
      border-color: @button-link-border-hover;
      background-color: @button-link-bg-hover;
      color: @button-link-color-hover;
    }
    &:visited {
      color: @button-link-color;
    }
    &.attach {
      color: #9a1a06;
    }
  }


  &--transparent {
    border-color: @button-transparent-border;
    background-color: transparent;
    color: @button-transparent-color;

    
    &:visited {
      color: @button-transparent-color;
    }
    &.btn--primary {
      border-color: @button-primary-border;
      color: @button-primary-border;
    }
    &.btn--secondary {
      border-color: @button-secondary-border;
      color: @button-secondary-border;
    }
    &:hover {
      border-color: @button-transparent-border-hover;
      background-color: @button-primary-bg;
      color: #fff;
    }
  }


  &--disabled,
  &[disabled] {
    pointer-events: none; // ссылка не работает
    cursor: not-allowed;
    box-shadow: none;
    background-color: @button-disabled-bg-color;
    border-color: @button-disabled-border-color;
    color: @button-disabled-color;

    &:hover {
      border-color: @button-disabled-bg-color;
      background-color: @button-disabled-border-color;
      color: @button-disabled-color;
    }
    &:visited {
      color: @button-disabled-color;
    }
  }

}




@media (max-width: 1024px) {
  .btn {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: @screen-sm) { /* 768px по умолчанию */
  .btn,
  .content .btn {
    padding-left: 20px;
    padding-right: 20px;
  }
}
