/**
* modal form style
*
* Dependencies:
* 1. /src/components/wm.form/wm.form.less
* 2. /src/components/wm.buttons/wm.buttons.less
*/
.site-form-wrapper--modal {
  position: relative;
  background-color: #fff;
  max-width: 730px;
  margin: 15px auto;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  & .site-form__textarea {
    resize: none;
  }

  & .site-form {
    padding-top: 20px;
    padding-left: 0;
    padding-right: 0;
  }
  & .row {
    padding-left: 10px;
    padding-right: 10px;
  }
  & .col-xs-12 {
    padding-left: 32px;
    padding-right: 32px;
  }
  & .col-xs-6 {
    &:nth-child(odd) {
      padding-right: 32px;
    }
    &:nth-child(even) {
      padding-left: 32px;
    }
  }
  & .modal-top {
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 20px;
  }
  & .site-form__head {
    margin-bottom: 16px;
  }

  & .site-form__input {
    height: 50px;
    border-radius: 5px;
    border-color: #e6e6e6;
    background-color: #fafafa;
  }

  & .site-form__textarea {
    border-radius: 5px;
    border-color: #e6e6e6;
    background-color: #fafafa;
  }

  & .site-form__label {
    color: #000;
    margin-bottom: 10px;
  }
}
.modal-form-message-info-wrapper {
  position: absolute;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  visibility: visible;
  background-color: #fff;
  padding-left: 3.5%;
  padding-right: 3.5%;
  text-align: center;

  &.disabled {
    z-index: -1;
    visibility: hidden;
  }
}

.modal-form__message-info {
  width: 100%;
  text-align: center;
  font-size: 22px;
  color: @heading-color;
  margin-bottom: 40px;

  & > div {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.modal-form__message-caption {
  color: @text-color;
}

.js-link-modal {
  outline: none;
}

// успешная отправка данных 
#success {
  padding-top: 50px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  min-width: 300px;
  min-height: 160px;
  font-family: 'robotoslab';
  font-weight: 700;
  font-size: 18px;
}

// форма авторизации
#login {
  max-width: 446px;
}

// форма регистрации
#register {
  max-width: 446px;
}

// форма заказать звонок
#callback {
  max-width: 446px;
}

// форма премиум-доступ
#premium {
  max-width: 446px;
}

// выбор города
#city {
  padding-bottom: 20px;
  & .city_letter {
    font-size: 20px;
    font-weight: 700;
    &:before {
      content: attr(data-letter);
    }
  }
  & .city_item {
    font-size: 16px;
    font-weight: 400;
  }
  & .col-xs-4 {
    &:first-child {
      padding-left: 32px;
    }
    &:last-child {
      padding-right: 32px;
    }
  }
}

#city-provider {
  padding-bottom: 20px;
  & .city_letter {
    font-size: 20px;
    font-weight: 700;
  }
  & .city_item {
    font-size: 16px;
    font-weight: 400;
  }
  & .col-xs-4 {
    &:first-child {
      padding-left: 32px;
    }
    &:last-child {
      padding-right: 32px;
    }
  }
}

// выбор города с чекбоксами
#region {
  padding-bottom: 20px;
  & .region_letter {
    font-size: 20px;
    font-weight: 700;
  }
  & .region_item {
    font-size: 16px;
    font-weight: 400;
  }
  & .col-xs-4 {
    &:first-child {
      padding-left: 32px;
    }
    &:last-child {
      padding-right: 32px;
    }
  }
  & .region_controls {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    margin-top: 20px;
    &-item {
      margin-right: 5px;
      outline: none !important;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  & .mfp-close {
    display: none !important;
    opacity: 0 !important;
    z-index: -666 !important;
  }
}

// форма прямой заявки
#order-straight {
  & .row {
    margin-bottom: 10px;
    &:last-child .site-form-element-wrapper {
      text-align: center;
    }
  }
  &.site-form-wrapper--modal .site-form__head {
    margin-bottom: 0;
  }
  &.site-form-wrapper--modal .col-xs-6:nth-child(odd) {
    padding-left: 32px;
    padding-right: 10px;
  }
  &.site-form-wrapper--modal .col-xs-6:nth-child(even) {
    padding-left: 10px;
    padding-right: 32px;
  }
  & .site-form-element-wrapper {
    margin-bottom: 10px;
  }
  & .modal-top {
    padding-bottom: 16px;
  }
  & .count {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  & input[name="order-straight[count]"] {
    width: 90%;
    margin-right: 2%;
  }
  & label[for="order-straight[count]"] {
    font-size: 18px;
    width: 8%;
    margin-bottom: 0;
  }
  & .radio {
    position: relative;
    top: -6px;
  }
  & .order-straight__results {
    display: table;
    position: relative;
    width: 100%;
    border-collapse: collapse;
    @media (max-width: 579px) {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
    } 
  }
  & .order-straight__row {
    display: table-row;
    @media (max-width: 579px) {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #e1e1e1;
      margin-bottom: 10px;
    }
    @media (max-width: 419px) {
      flex-direction: column;
      align-items: flex-start !important;
    }
  }
  & .order-straight__cell {
    display: table-cell;
    border: 1px solid #e1e1e1;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    &.stats {
      margin-bottom: 0;
    }
    @media (max-width: 579px) {
      display: block;
      border: none;
      text-align: left;
    }
  }
  & .order-straight__provider_name {
    font-family: 'robotoslab';
    font-size: 14px;
    font-weight: 700;
  }
  & .order-straight__provider_phone {
    display: block;
    font-weight: 700;
    color: #000;
    text-decoration: none;
  }
  & .order-straight__sum {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    &_property {
      font-size: 18px;
      margin-right: 10px;
    }
    &_value {
      font-weight: 700;
      font-size: 21px;
    }
  }
}

// форма добавления в заявку
#order-add {
  &.site-form-wrapper--modal {
    max-width: 800px;
  }
  & .order-add__table {
    display: table;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      display: block;
    }
  }
  & .order-add__head {
    display: table-header-group;
    font-weight: 700;
    @media (max-width: 768px) {
      display: none;
    }
  }
  & .order-add__results {
    display: table-row-group;
    @media (max-width: 768px) {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
    }
  }
  & .order-add__row {
    display: table-row;
    @media (max-width: 768px) {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: space-between;
      border: 1px solid #e1e1e1;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 16px;
      padding-right: 16px;
      margin-bottom: 20px;
    }
    @media (max-width: 579px) {
      flex-direction: column;
    }
  }
  & .order-add__cell {
    display: table-cell;
    text-align: center;
    border: 1px solid #e1e1e1;
    @media (max-width: 768px) {
      display: block;
      border: none;
      text-align: left;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 0;
    }
    &.count {
      & input {
        max-width: 100px;
        border: 1px solid #e1e1e1;
        border-radius: 3px;
        background-color: #fafae6;
        outline: none;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-left: 5px;
        padding-right: 5px;
        &:focus {
          outline: none;
        }
      }
    }
  }
  & .order-add__controls {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    @media (max-width: 1024px) {
      justify-content: flex-start;
      flex-direction: column;
    }
    & .btn--link {
      padding-left: 0;
      padding-right: 0;
    }
  }
}