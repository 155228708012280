/**
* objects
*/

.object-filter {
  @control-border-color-state-default:      @gray--darken;
  @control-border-color-state-active:       @primary-color;

  @control-background-color-state-default:  #fff;
  @control-background-color-state-active:   @primary-color;

  @control-text-color-state-default:        #000;
  @control-text-color-state-active:         #fff;

  @text-color-state-default:                #000;
  @text-color-state-colored-default:        @link-color;
  @text-color-state-colored-active:         @link-hover;


  @object-filter-flat-result-background-color: #f6f6f6;


  &-form {

    &-item-wrapper {
      padding: 0;
      border: 0;
      margin: 0 0 20px;
    }

    &-item__head {
      display: block;
      max-width: 100%;
      padding: 0;
      margin-bottom: 3px;
      white-space: normal;
    }

    &-control-list {
      display: flex;
      align-items: center;
      margin-right: -5px;
      margin-left: -5px;
    }

    &-control-item {
      padding-left: 5px;
      padding-right: 5px;
    }

    &-control__input {
      width: 100px;
      height: 40px;
      padding-left: 5px;
      padding-right: 5px;

      border-color: @control-border-color-state-default;
      border-width: 2px;
      border-style: solid;
      background-color: @control-background-color-state-default;

      &:hover,
      &:focus {
        background-color: #fff;
        border-color: @control-background-color-state-active;
        outline: 0;
        color: #000;
      }

      &:focus {
        outline: 0;
      }

      &:checked ~ .object-filter-form__label {

        &--checkbox {
          background-color: @control-background-color-state-active;
          border-color: @control-border-color-state-active;
          color: @control-text-color-state-active;
        }
      }

      &:focus  ~ .object-filter-form__label {
        border-color: @control-border-color-state-active;
      }
    }

    &__label {
      position: relative;
      width: auto;
      min-width: 40px;
      height: 40px;
      padding: 0 5px;
      margin-bottom: 20px;

      border-color: @control-border-color-state-default;
      line-height: 37px;
      text-align: center;

      &:hover {
        border-color: @control-background-color-state-active;
      }

      &--text {
        padding-left: 0;
        padding-right: 5px;
        border: none;
        background-color: @control-background-color-state-default;
        color: @control-text-color-state-default;
        text-align: center;
        transition: 0.15s all ease-in;

        &:focus,
        &:hover {
          background-color: @control-background-color-state-default;
          border: none;
          outline: 0;
          color: @control-text-color-state-default;
        }
      }

    }

    /**
    * irs slider custom style
    */
    .irs {
      height: 49px;

      &-bar {
        top: 25px;
        height: 5px;
        border-top: 0;
        border-bottom: 0;
        background: @control-border-color-state-active;
      }

      &-line {
        top: 25px;
        height: 5px;
        border-top: 0;
        border-bottom: 0;
        background: #adadad;
      }

      &-slider {
        top: 18px;
        width: 20px;
        height: 20px;
        background: @control-border-color-state-active;
        border: 2px solid white;
        border-radius: 50%;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);

        &.from,
        &.to {
          cursor: pointer;
          cursor: -webkit-grabbing;
        }
      }

    }

    &-range-slider-wrapper {
      min-height: 49px;
    }

  }





  /**
  * результаты поиска
  */
  &-content {

    &-form__checkbox {
      & .icon {
        opacity: 0;
        transition: 0.15s opacity ease-in;
      }
      &:checked + .object-filter-content-form__label {
        border-color: @control-border-color-state-active;
        background-color: @control-background-color-state-active;
        color: @control-text-color-state-active;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
      }
    }

    &-form__checkbox--icon {
      & + .object-filter-content-form__label {
        & .icon {
          fill: @control-text-color-state-active;
          opacity: 0;
          transition: 0.15s opacity ease-in;
       }
      }

      &:checked + .object-filter-content-form__label {
        & .icon {
          opacity: 1;
        }
      }
    }

    &-form__label {
      font-size: 14px;
      min-width: 70px;
      height: 40px;
      line-height: 36px;
      padding: 0;
      margin-right: 10px;
      margin-bottom: 10px;

      color: @text-color-state-default;
      background-color: @control-background-color-state-default;
      border-color: @control-border-color-state-default;
      text-align: center;


      &.active {
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
      }

      &:hover {
        box-shadow: none;
        color: @text-color-state-default;
        background-color: @control-background-color-state-default;
        border-color: @control-border-color-state-active;
      }
    }

    &-item {
      padding-top: 15px;
      padding-bottom: 20px;
      margin-bottom: 30px;
      background-color: @object-filter-flat-result-background-color;
    }

    // &-flat-type-list {
    //   display: flex;
    //   flex-wrap: wrap;
    // }

    &-apartement-item {
      position: relative;
      background-color: @control-background-color-state-default;
      padding: 20px 10px;
      margin-bottom: 20px;
      transition: 0.15s box-shadow ease-in;
      overflow: hidden;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        box-shadow: 0 0 20px rgba(0, 0, 1, 0.2);

        & .object-filter-content-apartement__animation-line {
          transform: translateX(0);
        }

        & .object-filter-content-apartement-text__main-info {
          color: @control-border-color-state-active;
        }
      }
    }

    &-apartement-text-wrapper {
      margin-bottom: 39px;
    }

    &-apartement__head {
      display: inline-block;
      margin-bottom: 4px;
      font-size: 24px;
      font-weight: 300;
      color: @text-color-state-default;
      text-decoration: none;
    }

    &-apartement__caption {
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 28px;
    }

    &-apartement-info-wrapper {
      padding-top: 24px;
    }

    &-apartement__animation-line {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: @control-background-color-state-active;
      transform: translateX(-104%);
      transition: 0.35s transform ease-in;
    }

    &-apartement-image-wrapper {
      display: block;
      overflow: hidden;
    }

    &-apartement__image {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }

    &-apartement-text__main-info {
      font-size: 36px;
      line-height: 1;
      color: @text-color-state-colored-default;
      transition: 0.35s color ease-in;
    }

    &-list {
      height: 100%;
    }

  }



  /**
  * object-filter-result
  */
  &-result {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;


    &-info {
      display: flex;
      align-items: center;
    }

    &-info__head {
      font-weight: 700;
      margin-right: 25px;
    }

    &-info-item {
      margin-right: 15px;
      border-color: @control-border-color-state-default;
      background-color: @control-background-color-state-default;
      cursor: default;
      &:last-child {margin-right: 0;}
    }

    &-sort-wrapper {
      display: flex;
      align-items: center;
    }

    &-sort__head {
      margin-right: 25px;
      font-weight: 700;
    }

    &-sort-list {
      display: flex;
    }

    &-sort-item {
      display: flex;
      align-items: center;
      text-decoration: none;
      margin-right: 18px;

      &:last-child { margin-right: 0; }

      &.active {
        .object-filter-result-sort-item__icon {
          transform: rotate(180deg);
        }
      }

      &:hover {
        & .object-filter-result-sort-item__icon {
          fill: @text-color-state-colored-active;
        }

        & .object-filter-result-sort-item__text {
          color: @text-color-state-colored-active;
        }
      }
    }

    &-sort-item-link {
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    &-sort-item__text {
      padding-right: 10px;
      color: @text-color-state-colored-default;
    }

    &-sort-item__icon {
      width: 12px;
      height: 12px;
      fill: @text-color-state-colored-default;
      transform: rotate(0);
    }

    &-info-list {
      display: flex;
      align-items: center;
    }

  }
}







/**
* @screen-lg - 1200 by default
*/
@media (max-width: @screen-lg) {
  .object-filter-result-info {
    display: block;
  }
  .object-filter-result-info__head {
    margin-bottom: 5px;
    margin-right: 0;
  }
}

/**
* @screen-md - 1024 by default
*/
@media (max-width: (@screen-md - 1) ) {
  .object-filter-result {
    display: block;
    margin-bottom: 20px;
  }
  .object-filter-result-info {
    margin-bottom: 20px;
  }
  .object-filter-content-apartement-text-link-wrapper {
    &:last-of-type {
      margin-bottom: 20px;
    }
  }
}

/**
* @screen-sm - 768 by default
*/
@media (max-width: (@screen-sm) ) {
  .object-filter-content-apartement-text-wrapper {
    margin-bottom: 10px;
  }
}

/**
* @screen-sm - 768 by default
*/
@media (max-width: (@screen-sm - 1) ) {
  .object-filter-result-info-list {
    flex-direction: column;
    align-items: flex-start;
  }
  .object-filter-result-sort-list {
    display: block;
  }
  .object-filter-result-sort-item {
    width: 100%;
    margin-right: 0;
  }
  .object-filter-result-info-item {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .object-filter-result-sort-wrapper {
    display: block;
  }
}
